import { FunctionComponent, useReducer } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { INITIAL_SNACKBAR_STATE, SnackbarAPIContext, snackbarReducer } from './reducer';

export const SnackbarProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(snackbarReducer, INITIAL_SNACKBAR_STATE);
  const { dismissable, id, message, open, severity } = state;

  return (
    <SnackbarAPIContext.Provider value={{ dispatch, state }}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={6000}
        key={id}
        onClose={(event, reason) => {
          if (reason === 'clickaway') return;
          dispatch({ type: 'close' });
        }}
        open={open}
      >
        <Alert
          onClose={dismissable ? () => dispatch({ type: 'close' }) : undefined}
          severity={severity}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
    </SnackbarAPIContext.Provider>
  );
};
