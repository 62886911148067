import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  gridFabric: {
    palette: {
      blue: '#2b7b9c',
      blueHover: '#3799c3',
      green: '#00b43b',
      greenHover: '#00cb43',
      lightBlue: '#269afa',
      lightBlueHover: '#42a7fa',
      lightGreen: '#ccf0d7',
      lightGreenHover: '#ccf0d7',
      orange: '#f8b133',
      orangeHover: '#f8b133',
      red: '#db0000',
      redHover: '#f00',
    },
    size: {
      headerHeight: 119,
      xsHeaderHeight: 80,
    },
  },
  overrides: {
    MuiAccordionSummary: {
      root: {
        paddingRight: 28,
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&:active, &:hover': {
          boxShadow: 'none',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          display: 'flex',
          minHeight: '100vh',
        },
        html: {
          minHeight: '100%',
        },
        '#root': {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: 24,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 24,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiMenu: {
      paper: {
        border: '1px solid #dbdbdb',
      },
    },
  },
  palette: {
    background: {
      default: '#F1F2F5',
    },
    divider: '#dbdbdb',
    primary: {
      main: '#006dc7',
    },
    text: {
      disabled: '#adadad',
      primary: '#4a4a4a',
    },
  },
  props: {
    MuiMenu: {
      elevation: 0,
      PaperProps: {
        square: true,
      },
    },
  },
  shape: {
    // @ts-ignore todo: material-ui types don't allow strings for borderRadius
    borderRadius: '.35rem',
  },
});
