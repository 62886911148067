let apiRoot = 'https://api-qa.oncleanspark.com:8445/api/v1.0';
if (process.env.DEV_API === 'prod') {
  apiRoot = 'https://api.oncleanspark.com:8445/api/v1.0';
}

export const api = {
  root: apiRoot,
  version: '1.0',
};

export const authCallbackRelativePath = '/auth-callback';

export const auth = {
  clientId: 'BTjDqHupoWFlu5k0qKKjAzByVW5arSNJ',
  domain: 'gridfabric-clsk.us.auth0.com',
  audience: 'https://gridfabric/platform',
  redirectUri: `${window.location.origin}${authCallbackRelativePath}`,
  responseType: 'token id_token',
  scope: 'openid',
  cacheLocation: window.Cypress ? ('localstorage' as const) : ('memory' as const),
};

export const venLogs = {
  /** Maximum number of log lines per request */
  maxLinesPerRequest: 100,
  /** Whether or not to queue new log lines and stagger display */
  queueEnabled: true,
  /** Maximum time before all queued lines are displayed (ms) */
  queueMaxInterval: 4000,
  /** Maximum time between queue ticks (ms) */
  queueMaxTickDuration: 150,
  /** How frequently to refetch logs (ms) */
  refetchInterval: 5000,
};

window.cs = window.cs || {};
export const featureFlags: FeatureFlags = {
  isTestEnvironment: false,
  // Allow cypress tests to override feature flags
  ...window.cs?.featureFlags,
};
window.cs.featureFlags = featureFlags;

interface FeatureFlags {
  isTestEnvironment: boolean;
}

declare global {
  interface Window {
    cs: {
      featureFlags: Partial<FeatureFlags>;
    };
    Cypress?: unknown;
  }
}
