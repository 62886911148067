import { FormikValues } from 'formik';
import { get } from 'lodash';
import { TextFieldProps } from '@material-ui/core';

/**
 * Get formik input props
 *
 * @param formik
 * @param id
 * @param options
 */
export function formikProps(
  { errors, handleBlur, handleChange, touched, values = {} }: FormikValues,
  id: string,
  { overrides = {}, showErrorsBeforeBlur = false, showInlineErrors = true }: FormikPropsOptions = {}
) {
  return {
    error: get(touched, id) && Boolean(get(errors, id)),
    helperText: (showInlineErrors && get(touched, id) && get(errors, id)) || overrides.helperText,
    id,
    onBlur: handleBlur,
    onChange: handleChange,
    onKeyPress: showErrorsBeforeBlur ? handleBlur : undefined,
    value: get(values, id),
  };
}
export interface FormikPropsOptions {
  overrides?: Pick<TextFieldProps, 'helperText'>;
  showErrorsBeforeBlur?: boolean;
  showInlineErrors?: boolean;
}
