import { ReactNode } from 'react';
import { Box, createStyles, Typography, useMediaQuery } from '@material-ui/core';
import logo from '../../assets/logo.png';
import { makeStyles, useTheme } from '../../helpers';

const IMAGE_SIZE = 65;
const IMAGE_SIZE_XS = 48;

const Logo = ({ title = '' }: LogoProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const imgSize = isVerySmallScreen ? IMAGE_SIZE_XS : IMAGE_SIZE;
  return (
    <>
      <Box alignSelf="center" paddingRight={2}>
        <img alt="Logo" height={imgSize} src={logo} width={imgSize} />
      </Box>
      <Box alignSelf="center" flexGrow={1}>
        <Typography
          className={classes.title}
          color="textSecondary"
          variant={isVerySmallScreen ? 'subtitle1' : 'h5'}
        >
          GridFabric
        </Typography>
        <Typography className={classes.title} variant={isVerySmallScreen ? 'h6' : 'h4'}>
          Plaid {title}
        </Typography>
      </Box>
    </>
  );
};

export interface LogoProps {
  title?: ReactNode;
}

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      lineHeight: '1em',
    },
  })
);

export default Logo;
