import * as Yup from 'yup';

export enum VENEndpoints {
  startDistributeEvent = 'Start Distribute Event',
  completeDistributeEvent = 'Complete Distribute Event',
  event = 'Event',
  startEvent = 'Start Event',
  startEventInterval = 'Start Event Interval',
  cancelEvent = 'Cancel Event',
  archiveEvent = 'Archive Event',
  endEvent = 'End Event',
}

export type VENConfiguration = Record<string, unknown> & {
  plugin: Record<string, unknown> & {
    endpoints: Record<string, Record<string, string>> & {
      event: {
        [key in keyof typeof VENEndpoints]: string;
      } &
        Record<string, string>;
    };
    heartbeat: {
      callbackIntervalSeconds: number;
      enable: boolean;
    };
    retryIntervalInSeconds: number;
  };
};

export enum VENStatus {
  STOPPED = 'stopped',
  STARTED = 'started',
}

export interface VEN {
  id: string;
  softwareVersion: string;
  name: string;
  status: VENStatus;
  configuration: VENConfiguration;
}

export interface VENLogLine {
  message: string;
  timestamp: string;
}

const eventShape: Record<string, Yup.AnySchema> = {};
(Object.keys(VENEndpoints) as (keyof typeof VENEndpoints)[]).forEach((id) => {
  eventShape[id] = Yup.string().label(VENEndpoints[id]).url('URL must be valid');
});

export const schemaVENConfiguration = Yup.object({
  plugin: Yup.object({
    endpoints: Yup.object({
      event: Yup.object(eventShape).required(),
    }).required(),
    heartbeat: Yup.object({
      callbackIntervalSeconds: Yup.number()
        .label('Callback Interval')
        .required('Field required')
        .min(1),
    }).required(),
    retryIntervalInSeconds: Yup.number().label('Retry Interval').required('Field required').min(1),
  }).required(),
}).required();
