export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_NUMBER_OF_CONDITIONS_REQUIRED = 3;
export const PASSWORD_CONDITIONS_MESSAGE = `Password must contain at least ${PASSWORD_NUMBER_OF_CONDITIONS_REQUIRED} of the following: lower case letters (a-z), upper case letters (A-Z), numbers (0-9), special characters (ex: !@#$%^&*)`;

export type PasswordCondition = 'hasLowerCase' | 'hasUpperCase' | 'hasNumber' | 'hasSpecialChar';
export type PasswordConditions = [
  hasLowerCase: boolean,
  hasUpperCase: boolean,
  hasNumber: boolean,
  hasSpecialChar: boolean
];

/**
 * Test a password against conditions
 *
 * @param password
 */
export function getPasswordConditions(password = ''): PasswordConditions {
  return [
    /[a-z]/.test(password),
    /[A-Z]/.test(password),
    /\d/.test(password),
    new RegExp(PASSWORD_SPECIAL_CHARACTERS).test(password),
  ];
}

/**
 * Check if a password meets enough conditions
 *
 * @param password
 */
export function checkPasswordConditions(password = '') {
  return (
    getPasswordConditions(password).reduce(
      (numValidConditions, conditionIsValid) => numValidConditions + Number(conditionIsValid),
      0
    ) >= PASSWORD_NUMBER_OF_CONDITIONS_REQUIRED
  );
}

// OWASP Special Characters: https://www.owasp.org/index.php/Password_special_characters
// Source: https://github.com/auth0/password-sheriff/blob/master/lib/rules/contains.js
export const PASSWORD_SPECIAL_CHARACTERS = [
  ' ',
  '!',
  '"',
  '#',
  '\\$',
  '%',
  '&',
  "'",
  '\\(',
  '\\)',
  '\\*',
  '\\+',
  ',',
  '-',
  '\\.',
  '/',
  ':',
  ';',
  '<',
  '=',
  '>',
  '\\?',
  '@',
  '\\[',
  '\\\\',
  '\\]',
  '\\^',
  '_',
  '`',
  '{',
  '\\|',
  '}',
  '~',
].join('|');
