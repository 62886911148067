import { makeStyles as muiMakeStyles, useTheme as muiUseTheme } from '@material-ui/core';
import { Styles, WithStylesOptions } from '@material-ui/styles/withStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

/**
 * Custom theme makeStyles wrapper
 *
 * @param styles
 * @param options
 */
export function makeStyles<Props extends object = {}, ClassKey extends string = string>(
  styles: Styles<CustomTheme, Props, ClassKey>,
  options?: Omit<WithStylesOptions<CustomTheme>, 'withTheme'>
) {
  return muiMakeStyles(styles, options);
}

/**
 * Custom theme useTheme wrapper
 */
export function useTheme<Theme = CustomTheme>() {
  return muiUseTheme<Theme>();
}

export interface GridFabricThemeOptions {
  palette: {
    blue: string;
    blueHover: string;
    green: string;
    greenHover: string;
    lightBlue: string;
    lightBlueHover: string;
    lightGreen: string;
    lightGreenHover: string;
    orange: string;
    orangeHover: string;
    red: string;
    redHover: string;
  };
  size: {
    headerHeight: number;
    xsHeaderHeight: number;
  };
}

export interface CustomTheme extends Theme {
  gridFabric: GridFabricThemeOptions;
}

// Extend Material-UI theme types
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    gridFabric: GridFabricThemeOptions;
  }
  interface ThemeOptions {
    gridFabric?: Partial<GridFabricThemeOptions>;
  }
}
declare module '@material-ui/core' {
  type Theme = CustomTheme;
}
