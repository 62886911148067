import { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import Logo, { LogoProps } from './Logo';

const LogoWrapper: FunctionComponent<LogoProps> = ({ children, ...logoProps }) => (
  <Box alignItems="center" display="flex" flexDirection="column">
    <Box display="inline-flex" marginBottom={4} marginTop={10}>
      <Logo {...logoProps} />
    </Box>
    {children}
  </Box>
);

export default LogoWrapper;
