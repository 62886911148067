import { createContext, Dispatch } from 'react';
import { UseMutationResult, UseQueryResult } from 'react-query';
import formatXML from 'xml-formatter';
import { ErrorResponse, VENControlMutation } from '../../../helpers/api.types';
import { VEN, VENLogLine } from '../../../models';
import { venLogs } from '../../../config';

export type DashboardAction =
  | { type: 'addLogs'; logs: VENLogLine[] }
  | { type: 'clearLogs' }
  | { type: 'loadVENList'; data: VEN[] }
  | { type: 'open'; key: keyof DashboardState['isOpen']; value: boolean }
  | { type: 'popLogsQueue' }
  | { type: 'updateVEN'; id: string; update: Omit<Partial<VEN>, 'id'> };

export interface DashboardState {
  isOpen: {
    configuration: boolean;
    log: boolean;
  };
  logs: string[];
  logsQueue: string[];
  logsQueueDuration: number;
  logsQueueEnabled: boolean;
  logsStartTS: string | false;
  selectedVENID: string | false;
  venList: VEN[] | null;
}

export interface DashboardAPI {
  dispatch: Dispatch<DashboardAction>;
  state: DashboardState;
  // todo: if including full react-query objects is causing too many updates create a more pared-down API
  venControlMutation: UseMutationResult<true, ErrorResponse, VENControlMutation> | null;
  venListQuery: UseQueryResult<VEN[], ErrorResponse> | null;
  venLogsQuery: UseQueryResult<VENLogLine[], ErrorResponse> | null;
}

export const INITIAL_DASHBOARD_STATE: DashboardState = {
  isOpen: {
    configuration: true,
    log: true,
  },
  logs: [],
  logsQueue: [],
  logsQueueDuration: venLogs.queueMaxTickDuration,
  logsQueueEnabled: venLogs.queueEnabled,
  logsStartTS: false,
  selectedVENID: false,
  venList: null,
};

export function dashboardReducer(state: DashboardState, action: DashboardAction): DashboardState {
  switch (action.type) {
    case 'addLogs':
      const { logs } = action;

      if (!Array.isArray(logs) || logs.length < 1) {
        // No logs to add
        return state;
      }

      const newLines: string[] = [];
      const startDate = !state.logsStartTS ? false : new Date(state.logsStartTS);
      const endDate = new Date(logs[logs.length - 1].timestamp);
      logs.forEach(({ message, timestamp }) => {
        const lineDate = new Date(timestamp);

        // Only add lines that occur after existing lines (prevent duplicates)
        if (!startDate || lineDate > startDate) {
          newLines.push(...expandLines(message));
        }
      });

      if (newLines.length === 0) {
        // No new lines to add
        return state;
      }

      const logsStartTS =
        !startDate || endDate > startDate ? endDate.toISOString() : state.logsStartTS;

      return state.logsQueueEnabled && state.logs.length > 0
        ? {
            // Queue new log entries
            ...state,
            logs: [...state.logs, ...state.logsQueue], // Add any old leftover queue items to logs
            logsQueue: newLines,
            logsQueueDuration: getLogsQueueDuration(newLines),
            logsStartTS,
          }
        : {
            // Don't queue new log entries
            ...state,
            logs: [...state.logs, ...state.logsQueue, ...newLines],
            logsQueue: [],
            logsStartTS,
          };

    case 'clearLogs':
      return {
        ...state,
        logs: [],
        logsQueue: [],
      };

    case 'popLogsQueue':
      const { logsQueue } = state;
      const line = logsQueue.shift() ?? '';
      return {
        ...state,
        logs: [...state.logs, line],
        logsQueue,
      };

    case 'loadVENList':
      const { data } = action;
      return {
        ...state,
        selectedVENID: data.length > 0 ? data[0].id : false,
        venList: [...data],
      };

    case 'updateVEN':
      return {
        ...state,
        venList: (state.venList ?? []).map((ven) => {
          if (ven.id !== action.id) return ven;
          return {
            ...ven,
            ...action.update,
          };
        }),
      };

    case 'open':
      return {
        ...state,
        isOpen: {
          ...state.isOpen,
          [action.key]: action.value,
        },
      };

    default:
      throw new Error();
  }
}

export const DashboardAPIContext = createContext<DashboardAPI>({
  dispatch: () => {},
  state: { ...INITIAL_DASHBOARD_STATE },
  venControlMutation: null,
  venListQuery: null,
  venLogsQuery: null,
});

/**
 * Get duration of log queue tick based on number of new lines
 *
 * @param lines
 */
function getLogsQueueDuration(lines: string[]) {
  const duration = venLogs.queueMaxInterval / lines.length;
  return duration > venLogs.queueMaxTickDuration ? venLogs.queueMaxTickDuration : duration;
}

/**
 * Split a message in to multiple lines when there's a payload to format
 *
 * @param message
 */
function expandLines(message: string): string[] {
  // Look for XML in message
  const parsedXML = parseXML(message);
  if (parsedXML) {
    return parsedXML;
  }

  // Look for JSON in message
  const parsedJSON = parseJSON(message);
  if (parsedJSON) {
    return parsedJSON;
  }

  // No expansion required
  return [message];
}

/**
 * Look for and parse JSON in a message string
 *
 * @param message
 */
function parseJSON(message: string): string[] | false {
  // Look for the start of a JSON payload
  const startPos = message.indexOf('{');
  if (startPos === -1) {
    // No JSON found
    return false;
  }

  const json = message.slice(startPos);
  const pre = message.slice(0, startPos);
  const lines = pre.length > 0 ? [pre] : [];

  try {
    // Format JSON and split by line
    const formattedJSON = JSON.stringify(JSON.parse(json), null, 2);
    formattedJSON.split('\n').forEach((line) => lines.push(line));
  } catch (error) {
    // Failed to format as JSON
    return false;
  }

  return lines;
}

/**
 * Look for and parse XML in a message string
 *
 * @param message
 */
function parseXML(message: string): string[] | false {
  // Look for an XML opening tag
  const startPos = message.indexOf('<?xml');
  if (startPos === -1) {
    // No XML found
    return false;
  }

  const xml = message.slice(startPos);
  const pre = message.slice(0, startPos);
  const lines = pre.length > 0 ? [pre] : [];

  try {
    // Format XML and split by line
    const formattedXML = formatXML(xml, { collapseContent: true, indentation: '  ' });
    formattedXML.split('\n').forEach((line) => lines.push(line));
  } catch (error) {
    // Failed to format as XML
    return false;
  }

  return lines;
}
