import { createContext, Dispatch, ReactNode } from 'react';
import { AlertProps } from '@material-ui/lab';

export type SnackbarAction = { type: 'new'; state: SnackbarState } | { type: 'close' };

export interface SnackbarState extends Required<Pick<AlertProps, 'severity'>> {
  dismissable: boolean;
  id: string;
  message: ReactNode;
  open: boolean;
}

export interface SnackbarAPI {
  dispatch: Dispatch<SnackbarAction>;
  state: SnackbarState;
}

export function snackbarReducer(state: SnackbarState, action: SnackbarAction) {
  switch (action.type) {
    case 'new':
      return {
        ...INITIAL_SNACKBAR_STATE,
        ...action.state,
      };

    case 'close':
      return {
        ...state,
        open: false,
      };

    default:
      throw new Error();
  }
}

export const INITIAL_SNACKBAR_STATE: SnackbarState = {
  dismissable: false,
  id: '',
  message: '',
  open: false,
  severity: 'info',
};

export const SnackbarAPIContext = createContext<SnackbarAPI>({
  dispatch: () => {},
  state: { ...INITIAL_SNACKBAR_STATE },
});
