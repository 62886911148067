import { EffectCallback, useEffect } from 'react';

/**
 * Calls useEffect once without dependency warnings
 *
 * @param effect
 */
export const useEffectOnce = (effect: EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};
