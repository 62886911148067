import { ReactNode, useCallback, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import {
  SnackbarAPIContext,
  SnackbarState,
} from '../components/module/snackbar-notification/reducer';

/**
 * Notification dispatch callback hook
 */
export function useNotification() {
  const { dispatch } = useContext(SnackbarAPIContext);
  return useCallback(
    (
      severity: SnackbarState['severity'],
      message: ReactNode,
      { dismissable = false }: Partial<Pick<SnackbarState, 'dismissable'>> = {}
    ) => {
      dispatch({
        type: 'new',
        state: {
          dismissable,
          id: uuid(),
          message,
          open: true,
          severity,
        },
      });
    },
    [dispatch]
  );
}
