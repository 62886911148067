import { MouseEvent, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AppBar,
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';
import clsx from 'clsx';
import Logo from '../../ui/Logo';

export function Header() {
  const classes = useStyles();
  const { logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar className={classes.root} elevation={0} position="static">
      <Toolbar className={classes.toolbar}>
        <Logo />
        <a
          className={classes.link}
          href="https://plaid-docs.gridfabric.io/web-demo/"
          rel="noreferrer"
          tabIndex={-1}
          target="_blank"
        >
          <IconButton
            classes={{
              root: classes.iconButton,
              label: classes.iconButtonLabel,
            }}
          >
            <HelpIcon className={clsx(classes.svg, classes.aboutIcon)} />
            About
          </IconButton>
        </a>
        <span className={classes.link}>
          <IconButton
            aria-controls="account-menu"
            aria-haspopup="true"
            classes={{
              root: classes.iconButton,
              label: classes.iconButtonLabel,
            }}
            onClick={handleClick}
          >
            <AccountCircleIcon className={clsx(classes.svg, classes.accountIcon)} />
            Account
          </IconButton>
        </span>
        <Menu
          id="account-menu"
          anchorEl={anchorEl}
          classes={{
            paper: classes.menu,
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              logout({ returnTo: `${window.location.origin}` });
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
      color: 'rgba(0, 0, 0, 0.9)',
      minHeight: theme.gridFabric.size.headerHeight,
      [theme.breakpoints.down('xs')]: {
        minHeight: theme.gridFabric.size.xsHeaderHeight,
      },
    },
    aboutIcon: {
      color: theme.gridFabric.palette.lightBlue,
    },
    accountIcon: {
      color: theme.gridFabric.palette.orange,
    },
    iconButton: {
      borderRadius: 0,
      height: '100%',
      width: theme.gridFabric.size.headerHeight,
      '&:hover': {
        backgroundColor: '#f2f7fa',
      },
      [theme.breakpoints.down('xs')]: {
        width: theme.gridFabric.size.xsHeaderHeight,
      },
    },
    iconButtonLabel: {
      flexFlow: 'column',
      fontSize: '1rem',
    },
    link: {
      borderLeft: `.07rem solid ${theme.palette.divider}`,
      textDecoration: 'none',
    },
    menu: {
      marginTop: theme.spacing(10),
      minWidth: 120,
    },
    svg: {
      fontSize: '2.5rem',
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.down('xs')]: {
        fontSize: '2rem',
      },
    },
    toolbar: {
      alignItems: 'stretch',
      paddingRight: 0,
      minHeight: theme.gridFabric.size.headerHeight,
      [theme.breakpoints.down('xs')]: {
        minHeight: theme.gridFabric.size.xsHeaderHeight,
      },
    },
  })
);
