import { useContext } from 'react';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { VEN, VENConfiguration, VENLogLine, VENStatus } from '../models';
import {
  DataHandler,
  ErrorResponse,
  ErrorResponseWithValidationErrors,
  UserActivateMutation,
  UserCreateMutation,
  UserProfileResponse,
  VENConfigurationMutation,
  VENControlMutation,
  VENLogsQueryOptions,
  VENResponse,
} from './api.types';
import { DashboardAPIContext } from '../components/module/dashboard/reducer';
import { useNotification } from './notification';
import { api, featureFlags, venLogs } from '../config';

export const apiURL = (path: string) => `${api.root}${path}`;

const { isTestEnvironment = false } = featureFlags;

/**
 * Create a VEN log line
 *
 * @param message
 * @param level
 */
export function createVENLogLine(message: string, level: string = 'info'): VENLogLine {
  const time = new Date().toISOString().replace('T', ' ').replace('Z', '');
  return {
    timestamp: new Date().toISOString(),
    message: `[${time}] [plaid-web] [${level}] ${message}`,
  };
}

/**
 * Type guard for error responses with validation errors
 *
 * @param error
 */
export function hasValidationErrors(
  error: ErrorResponse | ErrorResponseWithValidationErrors
): error is ErrorResponseWithValidationErrors {
  return Object.prototype.hasOwnProperty.call(error, 'validationErrors');
}

/**
 * User create mutation hook
 */
export function useUserCreateMutation(
  mutationOptions: UseMutationOptions<true, ErrorResponse, UserCreateMutation> = {}
) {
  return useMutation<true, ErrorResponse, UserCreateMutation>(
    async (body) =>
      fetch(apiURL(`/user/create`), {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(handleEmptyResponse),
    mutationOptions
  );
}

/**
 * User activate mutation hook
 */
export function useUserActivateMutation(
  mutationOptions: UseMutationOptions<true, ErrorResponse, UserActivateMutation> = {}
) {
  return useMutation<true, ErrorResponse, UserActivateMutation>(
    async (body) =>
      fetch(apiURL(`/user/activate`), {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(handleEmptyResponse),
    mutationOptions
  );
}

/**
 * User profile query hook
 */
export function useUserProfileQuery(
  queryOptions: UseQueryOptions<UserProfileResponse, ErrorResponse> = {}
) {
  const fetchWithAuth = useFetchWithAuth();
  const notification = useNotification();
  return useQuery<UserProfileResponse, ErrorResponse>(
    'userProfile',
    () => fetchWithAuth(apiURL('/user/profile')).then(handleJSONResponse()),
    {
      onError: ({ message }) => {
        notification('error', `Error retrieving user profile. ${message}`);
      },
      ...queryOptions,
    }
  );
}

/**
 * Virtual end node configuration mutation hook
 */
export function useVENConfigurationMutation() {
  const fetchWithAuth = useFetchWithAuth();
  const notification = useNotification();
  const { dispatch } = useContext(DashboardAPIContext);
  return useMutation<
    true,
    ErrorResponse | ErrorResponseWithValidationErrors,
    VENConfigurationMutation
  >(
    async ({ id, ...body }) =>
      fetchWithAuth(apiURL(`/ven/${id}/configuration`), {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(handleEmptyResponse),
    {
      onError: (error) => {
        notification(
          'error',
          <>
            Error updating VEN configuration. {error.message}
            {hasValidationErrors(error) && error.validationErrors.length > 0 && (
              <>
                <ul>
                  {error.validationErrors.map((validationError) => (
                    <li key={validationError}>{validationError}</li>
                  ))}
                </ul>
              </>
            )}
          </>
        );
      },
      onSuccess: (_, { configuration, id }) =>
        dispatch({
          type: 'updateVEN',
          id,
          update: {
            configuration: JSON.parse(configuration),
            status: VENStatus.STARTED,
          },
        }),
    }
  );
}

/**
 * Virtual end node control mutation hook
 */
export function useVENControlMutation(
  queryOptions: UseMutationOptions<true, ErrorResponse, VENControlMutation> = {}
) {
  const fetchWithAuth = useFetchWithAuth();
  const notification = useNotification();
  return useMutation<true, ErrorResponse, VENControlMutation>(
    async ({ action, id }) =>
      fetchWithAuth(apiURL(`/ven/${id}/control?action=${action}`), { method: 'POST' }).then(
        handleEmptyResponse
      ),
    {
      ...queryOptions,
      onError: ({ message }, { action }) => {
        notification(
          'error',
          `Error ${action === 'start' ? 'starting' : 'stopping'} VEN. ${message}`
        );
      },
    }
  );
}

/**
 * Virtual end node list query hook
 */
export function useVENListQuery(queryOptions: UseQueryOptions<VEN[], ErrorResponse> = {}) {
  const fetchWithAuth = useFetchWithAuth();
  return useQuery<VEN[], ErrorResponse>(
    'venList',
    () =>
      fetchWithAuth(apiURL('/ven')).then(
        handleJSONResponse<VEN[], VENResponse[]>((data) =>
          data.map((ven) => ({
            ...ven,
            configuration: JSON.parse(ven.configuration) as VENConfiguration,
            status: ven.status.toLowerCase() as VENStatus,
          }))
        )
      ),
    {
      // refetchInterval: 10000, // todo: should we refetch to sync with potential changes outside the current browser tab?
      refetchOnMount: !isTestEnvironment,
      refetchOnReconnect: !isTestEnvironment,
      refetchOnWindowFocus: !isTestEnvironment,
      ...queryOptions,
    }
  );
}

/**
 * Virtual end node logs query hook
 */
export function useVENLogsQuery(
  id: string | false,
  {
    endTS = false,
    startTS = false,
    topN = venLogs.maxLinesPerRequest,
    ...queryOptions
  }: VENLogsQueryOptions = {}
) {
  const fetchWithAuth = useFetchWithAuth();
  const notification = useNotification();
  const url = new URL(apiURL(`/ven/${id}/logs`));
  if (startTS) {
    url.searchParams.append('startTS', startTS);
  }
  if (endTS) {
    url.searchParams.append('endTS', endTS);
  }
  if (topN) {
    url.searchParams.append('topN', topN.toString());
  }
  return useQuery<VENLogLine[], ErrorResponse>(
    ['venLogs', id],
    () => fetchWithAuth(url.toString()).then(handleJSONResponse()),
    {
      onError: ({ message }) => {
        notification('error', `Error retrieving VEN logs. ${message}`);
      },
      refetchInterval: venLogs.refetchInterval,
      refetchOnMount: !isTestEnvironment,
      refetchOnReconnect: !isTestEnvironment,
      refetchOnWindowFocus: !isTestEnvironment,
      ...queryOptions,
    }
  );
}

/**
 * Generic empty response handler
 *
 * @param response
 */
export function handleEmptyResponse(response: Response) {
  if (response.status === 200) {
    return true;
  }
  return response.json().then((error) => Promise.reject(error));
}

/**
 * Generic JSON response handler
 *
 * @param dataHandler
 */
export const handleJSONResponse = <TDataOut extends unknown, TDataIn = TDataOut>(
  dataHandler: DataHandler<TDataOut, TDataIn> = (data) => data as TDataOut
) => (response: Response) => {
  if (response.status === 200 && response.body) {
    return response.json().then(dataHandler);
  }
  return response.json().then((error) => Promise.reject(error));
};

/**
 * Hook to wrap fetch in an authorization header
 */
function useFetchWithAuth() {
  const { getAccessTokenSilently } = useAuth0();
  return async (url: string, options: RequestInit = {}) => {
    const token = isTestEnvironment ? 'test' : await getAccessTokenSilently();
    return fetch(url, {
      ...options,
      headers: {
        Authorization: `Bearer ${token}`,
        ...options.headers,
      },
    });
  };
}
