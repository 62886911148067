import { Box, BoxProps, CircularProgress, Typography } from '@material-ui/core';

const LoadWithSpinner = ({
  message = 'Loading',
  noMessage = false,
  testId,
  ...boxProps
}: LoadWithSpinnerProps) => (
  <Box
    alignItems="center"
    data-testid={testId}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    {...boxProps}
  >
    {!noMessage && <Typography variant="subtitle1">{message}</Typography>}
    <CircularProgress size={50} />
  </Box>
);

export interface LoadWithSpinnerProps extends BoxProps {
  message?: string;
  noMessage?: boolean;
  testId?: string;
}

export default LoadWithSpinner;
