import { createContext, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import isEqual from 'lodash/isEqual';
import { UserProfile } from '../../../models';
import { useUserProfileQuery } from '../../../helpers';
import { featureFlags } from '../../../config';

export const UserProvider: FunctionComponent = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const { isTestEnvironment = false } = featureFlags;

  // Query user profile
  const [user, setUser] = useState<UserProfile | null>(null);
  const { isError: isUserError, isLoading: isUserLoading } = useUserProfileQuery({
    enabled: isAuthenticated || isTestEnvironment,
    onSuccess: (userProfile) => {
      const newUser = {
        ...userProfile,
        activeUntil: new Date(userProfile.activeUntil),
      };
      if (!isEqual(user, newUser)) {
        setUser(newUser);
      }
    },
  });

  // Handle authentication change
  useEffect(() => {
    if (!isAuthenticated) {
      // Logged out, unset profile
      setUser(null);
    }
  }, [isAuthenticated]);

  // Create a stable context reference
  const userContext = useMemo(
    () => ({
      user,
      isUserError,
      isUserLoading,
    }),
    [user, isUserError, isUserLoading]
  );

  return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>;
};

export const UserContext = createContext<{
  user: UserProfile | null;
  isUserError: boolean;
  isUserLoading: boolean;
}>({
  user: null,
  isUserError: false,
  isUserLoading: true,
});
