import { lazy, Suspense } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider, Typography } from '@material-ui/core';
import { ProtectedRoute } from './module/auth/ProtectedRoute';
import { SnackbarProvider } from './module/snackbar-notification/SnackbarProvider';
import { UserProvider } from './module/account/UserProvider';
import { theme } from './theme';
import { auth, authCallbackRelativePath } from '../config';
import LoadWithSpinner from './ui/LoadWithSpinner';
import LogoWrapper from './ui/LogoWrapper';

const ActivateAccount = lazy(() => import('./module/account/ActivateAccount'));
const DashboardContainer = lazy(() => import('./module/dashboard/DashboardContainer'));
const RegistrationContainer = lazy(() => import('./module/account/RegistrationContainer'));

const queryClient = new QueryClient();

export function Root() {
  return (
    <Auth0Provider {...auth}>
      <Helmet>
        <link rel="preconnect" href={`https://${auth.domain}`} />
      </Helmet>
      <Router>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <Suspense
              fallback={
                <LogoWrapper>
                  <LoadWithSpinner />
                </LogoWrapper>
              }
            >
              <SnackbarProvider>
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <Switch>
                    <ProtectedRoute path={authCallbackRelativePath}>
                      <Redirect to="/" />
                    </ProtectedRoute>
                    <Route path="/registration" exact>
                      <RegistrationContainer />
                    </Route>
                    <Route path="/activate-account" exact>
                      <ActivateAccount />
                    </Route>
                    <ProtectedRoute path="/" exact>
                      <DashboardContainer />
                    </ProtectedRoute>
                    <Route>
                      <LogoWrapper>
                        <Typography variant="h5">Page Not Found</Typography>
                      </LogoWrapper>
                    </Route>
                  </Switch>
                </MuiThemeProvider>
              </SnackbarProvider>
            </Suspense>
          </UserProvider>
        </QueryClientProvider>
      </Router>
    </Auth0Provider>
  );
}
