import { ReactElement, useContext } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Route, RouteProps } from 'react-router-dom';
import { featureFlags } from '../../../config';
import LogoWrapper from '../../ui/LogoWrapper';
import LoadWithSpinner from '../../ui/LoadWithSpinner';
import { UserContext } from '../account/UserProvider';
import { Header } from '../main/Header';

export interface ProtectedRouteProps extends RouteProps {
  children: ReactElement | null;
}

export const ProtectedRoute = ({ children, ...args }: ProtectedRouteProps) => {
  const { user } = useContext(UserContext);
  return (
    <Route
      render={() => {
        // Bypass authentication in test environment
        if (featureFlags.isTestEnvironment) {
          return children;
        }

        if (user && !user.userIsActive) {
          return (
            <>
              <Header />
              <Box margin={3}>
                <Alert severity="error" variant="filled">
                  <AlertTitle>Your account has not been activated</AlertTitle>
                  Check your e-mail for details on how to activate your account.
                </Alert>
              </Box>
            </>
          );
        }

        // Protect children with authentication
        const Component = withAuthenticationRequired(() => children, {
          onRedirecting: () => (
            <LogoWrapper>
              <LoadWithSpinner message="Authenticating" />
            </LogoWrapper>
          ),
        });
        return <Component />;
      }}
      {...args}
    />
  );
};
